'use-client'

class esAR {
    Sentences = {
        "TicketValidatorNotLoggedIn": "Necesitas iniciar sesión para validar la entrada.",
        "ReadTheCode": "Apunta la cámara de tu celular al código de la entrada.",
        "TicketValidated": "¡Entrada validada!",
        "InvalidTicket": "Entrada inválida.",
        "Loading": "Cargando",
        "NoValidatorSelected": "Ningún validador seleccionado.",
        "Validator": "Validador",

        "MyTickets": "Mis Entradas",
        "Search": "Buscar",
        "OpenQrCode": "Mostrar Código de Entrada",
        "TicketCode": "Código de Entrada",
        "ShareVoucher": "Compartir",
        "DownloadVoucher": "Descargar",
        "CheckoutSuccessInstructions": "¡Su pedido fue realizado con éxito! Sus entradas están disponibles en la pestaña 'Mis Entradas', dentro de 'Mi Cuenta'. Más detalles también fueron enviados al correo electrónico registrado.",

        "ContactUs": "Contáctenos",
        "BeforeCalling": "Antes de llamar...",
        "HaveYouSeen": "¿Has verificado si tu pregunta ya ha sido respondida en nuestra sección de",
        "FrequentlyAskedQuestions": "Preguntas Frecuentes",
        "CallTheServiceCenter": "Llama al centro de atención:",
        "SendAnEmail": "O envía un correo electrónico a",

        //RETORNOS ACESSOS
        "ACESSO_NAO_ENCONTRADO": "Codigo de entrada no encontrado",
        "TENTATIVA_DUPLICADA": "Intento duplicado",
        "ERRO_DESCONHECIDO": "Error desconocido",
    }
}

const output = new esAR();
export default output;
