'use-client'

class ptBR {
    Sentences = {
        "TicketValidatorNotLoggedIn": "Você precisa estar logado para validar o ingresso.",
        "ReadTheCode": "Aponte a câmera para o código do ingresso.",
        "TicketValidated": "Ingresso validado!",
        "InvalidTicket": "Ingresso inválido.",

        "Loading": "Carregando",
        "NoValidatorSelected": "Nenhum validador selecionado.",
        "Validator": "Validador",


        "MyTickets": "Meus ingressos",
        "Search": "Pesquisar",
        "OpenQrCode": "Exibir código do ingresso",
        "TicketCode": "Código do ingresso",
        "ShareVoucher": "Compartilhar",
        "DownloadVoucher": "Baixar",


        "ContactUs": "Fale conosco",
        "BeforeCalling": "Antes de ligar...",
        "HaveYouSeen": "Você já viu se a sua dúvida já não foi respondida na nossa sessão de",
        "FrequentlyAskedQuestions": "Dúvidas frequentes",
        "CallTheServiceCenter": "Ligue na central de atendimento:",
        "SendAnEmail": "Ou mande um e-mail para",

        //RETORNOS ACESSOS
        "ACESSO_NAO_ENCONTRADO": "Código não encontrado",
        "TENTATIVA_DUPLICADA": "Tentativa duplicada",
        "ERRO_DESCONHECIDO": "Erro desconhecido",
        "CATRACA_NAO_ENCONTRADA": "Catraca não encontrada",
        "TENTATIVA_DUPLICADA": "Tentativa duplicada",
    }
}

const output = new ptBR();
export default output;