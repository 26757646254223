import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import "./src/styles/global.css"
import "./src/styles/main.css"
import "./src/styles/notifier.css"

import React, { StrictMode } from 'react';

import { Templates } from "@dsi-arena/frontend-react-framework";
import useLoginService from 'services/dsi_login/loginService';
import useForgotPasswordService from 'services/dsi_login/forgotPasswordService';
import useLogoutService from 'services/dsi_login/logoutService';
import getUserService from 'services/dsi_login/getUserService';

import usePersonalInformationService, { userToUpdatePersonalInformationInput } from 'services/personalInformation/update';
import useUpdatePasswordService from 'services/personalInformation/updatePassword';
import useGetOrdersHistoryService from 'services/ordersHistory/ordersHistory';

import useRegisterService from 'services/dsi_login/registerService';
import CheckDocumentService from 'services/dsi_login/checkDocumentService';
import CheckEmailService from 'services/dsi_login/checkEmailService';
import { userToRegisterAccountInput } from 'services/dsi_login/registerService';

import useFetchAddressByZipCode from "services/geo/fetchStreetByZipCode";


import useGetTicketsFromUser from "services/dsi_app_api/getTicketsFromUser";
import useHandleJwtService from 'services/dsi_login/handleJwtService';
import useHandleAuthCodeService from 'services/dsi_login/handleAuthCodeService';

import PtBR from "languages/pt-BR";
import EnUS from "languages/en-US";
import EsAR from "languages/es-AR";

import { GetExtraTabs } from "components/molecules/extraTabs";

import { LogoImageComponent } from "services/internal/getImageFilesQuery";

export const WrapPageElementComponent = ({ element }) => {
    const { MainWithFooter } = Templates;

    return (
        <StrictMode>

            <MainWithFooter

                config={{
                    AuthEnabled: true,
                    SocialLoginEnabled: true,
                    GoogleOAuthProviderEnabled: true,
                    GoogleOAuthClientId: process.env.GOOGLE_OAUTH_CLIENT_ID,
                    GoogleOAuthRedirectUri: process.env.GOOGLE_OAUTH_REDIRECT_URI,
                    // UsernameField: "document",
                    // UsernameField: "email",
                    UsernameFields: ["email", "document"],
                    DocumentFieldEnabled: true,
                    DocumentFieldRequiredToRegister: false,
                    DocumentFieldRequiredToPurchase: true,
                    CheckExistingDocument: true,
                    CheckExistingEmail: true,
                    RequestUserInformationOnPayment: true,
                    DefaultUserCountry: "BR",
                    DisplayWarningForPurchaseRequiredUserFields: true,
                    PurchaseRequiredUserFields: [
                        "name",
                        "email",
                        "document",
                        "phone",
                        "zipCode",
                        "street",
                        "number",
                        "neighborhood",
                        "city",
                        "state",
                        "country"
                    ],


                    ShoppingCartEnabled: false,

                    LanguageEnabled: true,
                    EnabledLanguages: {
                        "pt-BR": {
                            CustomSentences: PtBR.Sentences,
                        },
                        "en-US": {
                            CustomSentences: EnUS.Sentences,
                        },
                        "es-AR": {
                            CustomSentences: EsAR.Sentences,
                        },
                    },

                    MyAccountExtraTabsFn: GetExtraTabs,

                    ThemeEnabled: true,
                    Themes: [
                        { name: "corinthians", logoFilename: "corinthians.png" },
                        { name: "gremio", logoFilename: "gremio.png" },
                        { name: "chapecoense", logoFilename: "chapecoense.png" },
                    ],

                    LogoImageComponent,

                    EnableCookieConsent: true,

                    EnabledCountries: {
                        "BR": {
                            allowedDocuments: ['CPF', 'CNPJ'],
                            documentMasks: ['999.999.999-99', '99.999.999/9999-99'],
                            validateDocumentService: () => (documentValue) => {
                                if (typeof documentValue !== 'string') {
                                    const error = "InvalidDocumentValueError";
                                    return { formattedDocumentValue: '', error };
                                }

                                const formattedDocumentValue = documentValue.replace(/\D/g, '');
                                if (formattedDocumentValue.length !== 11 && formattedDocumentValue.length !== 14) {
                                    return { formattedDocumentValue, error: "DocumentLengthError" };
                                }
                                return { formattedDocumentValue, error: null };
                            },
                            zipCodeMask: '99.999-999',
                            zipCodeLabel: 'ZipCode',
                            zipCodeRequired: true,
                        },
                        "US": {
                            allowedDocuments: ['Passport number'],
                            documentMasks: ['999999999'],
                            validateDocumentService: () => (documentValue) => {
                                if (typeof documentValue !== 'string') {
                                    const error = 'InvalidDocumentValueError';
                                    return { formattedDocumentValue: '', error };
                                }

                                const formattedDocumentValue = documentValue.replace(/\D/g, '');
                                if (formattedDocumentValue.length !== 9) {
                                    return { formattedDocumentValue, error: 'DocumentLengthError' };
                                }
                                return { formattedDocumentValue, error: null };
                            },
                            zipCodeMask: '99999',
                            zipCodeLabel: 'PostalCode',
                            zipCodeRequired: true,
                        },
                        "AR": {
                            allowedDocuments: ['Numero de passaporte'],
                            documentMasks: ['999999999'],
                            validateDocumentService: () => (documentValue) => {
                                if (typeof documentValue !== 'string') {
                                    const error = 'InvalidDocumentValueError';
                                    return { formattedDocumentValue: '', error };
                                }

                                const formattedDocumentValue = documentValue.replace(/\D/g, '');
                                if (formattedDocumentValue.length !== 8) {
                                    return { formattedDocumentValue, error: 'DocumentLengthError' };
                                }
                                return { formattedDocumentValue, error: null };
                            },
                            zipCodeMask: 'A9999AAA',
                            zipCodeLabel: 'PostalCode',
                            zipCodeRequired: false,
                        },
                    },
                }}

                services={{
                    //login
                    useLoginService,
                    useForgotPasswordService,
                    useLogoutService,
                    getUserService,
                    useHandleJwtService,
                    useHandleAuthCodeService,

                    //personal information
                    usePersonalInformationService,
                    userToUpdatePersonalInformationInput,
                    useUpdatePasswordService,
                    useGetOrdersHistoryService,

                    //register
                    useRegisterService,
                    CheckDocumentService,
                    CheckEmailService,
                    userToRegisterAccountInput,

                    //zipcode
                    useFetchAddressByZipCode,

                    useGetTicketsFromUser,
                }}

                //TODO: fetch it with graphql?
                defaultTheme={{
                    name: "corinthians",
                    logoFilename: "corinthians.png",
                }}

                //TODO: fetch it with graphql?
                entity={{
                    name: "Corinthians Sport Club Paulista",
                    document: "15.732.115/0001-48",
                    phone: "(47) 99769-4646",
                    address: "Rua Nove de Março, 820 - segundo andar",
                }}
            >
                {element}
            </MainWithFooter>

        </StrictMode >
    );
}

export const wrapPageElement = WrapPageElementComponent;