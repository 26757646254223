'use-client'

class enUS {
    Sentences = {
        "TicketValidatorNotLoggedIn": "You need to be logged in to validate the ticket.",
        "ReadTheCode": "Point your phone's camera at the ticket code.",
        "TicketValidated": "Ticket validated!",
        "InvalidTicket": "Invalid ticket.",
        "Loading": "Loading",
        "NoValidatorSelected": "No validator selected.",
        "Validator": "Validator",

        "MyTickets": "My Tickets",
        "Search": "Search",
        "OpenQrCode": "Display Ticket Code",
        "TicketCode": "Ticket Code",
        "ShareVoucher": "Share",
        "DownloadVoucher": "Download",

        "ContactUs": "Contact Us",
        "BeforeCalling": "Before calling...",
        "HaveYouSeen": "Have you checked if your question has already been answered in our",
        "FrequentlyAskedQuestions": "Frequently Asked Questions section",
        "CallTheServiceCenter": "Call the service center:",
        "SendAnEmail": "Or send an email to",

        //RETORNOS ACESSOS
        "ACESSO_NAO_ENCONTRADO": "Ticket code not found",
        "TENTATIVA_DUPLICADA": "Duplicate attempt",
        "ERRO_DESCONHECIDO": "Unknown error",
    }
}

const output = new enUS();
export default output;
